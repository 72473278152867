import Link from 'next/link'
import { NextSeo } from 'next-seo'

import Container from '~/components/Container'

const title = '404 - Mary Agrotechnologies'

export default function Custom404Page() {
  return (
    <Container className="flex-col bg-black px-6 mt-20">
      <NextSeo title={title} />
      <div className="max-w-6xl mx-auto bg-grow-box-fog bg-160 md:bg-cover bg-no-repeat bg-bottom">
        <section className="flex flex-col w-100 items-center justify-center pb-72 px-2">
          <h2 className="text-white text-center font-medium text-2xl md:text-3xl">
            Error 404
          </h2>
          <h3 className="text-lg md:text-xl text-white text-center py-6 md:py-8">
            We can&apos;t seem to find what you&apos;re looking for
          </h3>
          <div className="flex flex-col md:flex-row md:pt-4">
            <Link href="/">
              <a className="text-white text-center text-sm w-48 md:text-base md:w-64 py-3 rounded-full border border-white mb-5 mr-5">
                Back To Homepage
              </a>
            </Link>
            <Link href="/support">
              <a className="text-white text-center text-sm w-48 md:text-base md:w-64 py-3 rounded-full border border-white mb-5 whitespace-nowrap">
                Visit Mary Support
              </a>
            </Link>
          </div>
        </section>
      </div>
    </Container>
  )
}
